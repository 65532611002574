<div class="row">
  <div class="col-12">
    <div class="card shadow-none">
      <div class="header">
        <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
        <h6 class="text-white ml-2 title-header" >Log exécution : {{cron.name}}</h6>
      </div>
      <div class="card-content">
        <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
          <form (ngSubmit)="onSubmit();">
            <div class="row" style="display: flex!important;">
              <div class="col-lg-3">
                <div class="mb-3">
                  <label for="dateFrom">Date debut</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input class="form-control" id="dateFrom" readonly name="dp" [(ngModel)]="dateFrom" ngbDatepicker
                             #logDateFrom="ngbDatepicker" [maxDate]="maxDate" (click)="logDateFrom.toggle()">
                      <div class="input-group-append">
                        <div class="input-group-text" (click)="logDateFrom.toggle()">
                          <i class="fa fa-calendar" style="cursor: pointer;"></i>
                        </div>
                      </div>
                      &nbsp;
                      <ngb-timepicker [(ngModel)]="timeFrom" [seconds]="false" [hourStep]="1" [minuteStep]="30" [ngModelOptions]="{standalone: true}"></ngb-timepicker>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="mb-3">
                  <label for="dateFrom">Date Fin</label>
                  <div class="form-group">
                    <div class="input-group">
                      <input class="form-control" id="dateTo" readonly name="dp" [(ngModel)]="dateTo" ngbDatepicker
                             #logDateTo="ngbDatepicker" [minDate]="dateFrom" [maxDate]="maxDate" (click)="logDateTo.toggle()">
                      <div class="input-group-append">
                        <div class="input-group-text" (click)="logDateTo.toggle()">
                          <i class="fa fa-calendar" style="cursor: pointer;"></i>
                        </div>
                      </div>
                      &nbsp;
                      <ngb-timepicker [(ngModel)]="timeTo" [seconds]="false" [hourStep]="1" [minuteStep]="30" [ngModelOptions]="{standalone: true}"></ngb-timepicker>

                    </div>

                  </div>
                </div>
              </div>
              <!-- Etat -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="status">Etat</label>
                  <select aria-label="Floating label select example" class="form-control" id="status" name="status" [(ngModel)]="status" ngModel>
                    <option selected="" value="">Tous</option>
                    <option value="success">Succès</option>
                    <option value="error">En erreur</option>
                    <option value="warning">En warning</option>
                  </select>
                </div>
              </div>
              <!-- Automatique -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="status">Lancement</label>
                  <select aria-label="Floating label select example" class="form-control" [(ngModel)]="isAutomatic" id="isAutomatic" name="isAutomatic" ngModel>
                    <option selected="" value="null">Tous</option>
                    <option value="true">Automatique</option>
                    <option value="false">Manuel</option>
                  </select>
                </div>
              </div>
              <!-- DATA 1 -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="data1">{{cron.executionData1Label?.length ? cron.executionData1Label : 'Donnée 1'}}</label>
                  <input class="form-control" id="data1" name="data1" ngModel type="text" value="" [(ngModel)]="data1">
                </div>
              </div>
              <!-- DATA 2 -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="data2">{{cron.executionData2Label?.length ? cron.executionData2Label : 'Donnée 2'}}</label>
                  <input class="form-control" id="data2" name="data2" ngModel type="text" value="" [(ngModel)]="data2">
                </div>
              </div>
              <!-- DATA 3 -->
              <div class="col-md-3">
                <div class="form-floating mb-3">
                  <label for="data3">{{cron.executionData3Label?.length ? cron.executionData3Label : 'Donnée 3'}}</label>
                  <input class="form-control" id="data3" name="data3" ngModel type="text" value="" [(ngModel)]="data3">
                </div>
              </div>
            </div>
            <!-- .ligne 2 -->
            <!-- Submit + products count -->
            <div class="row blocBtnSearch">
              <div class="col-md-9">
                <div class="d-flex justify-content-start product-count"></div>
              </div>
              <div class="col-md-3">
                <div class="d-flex justify-content-end">
                  <button class="btn btn-primary" type="submit" id="search">
                    <i class="ft-search mr-1"></i>Rechercher
                  </button>
                </div>
              </div>
            </div>
          </form>

        </div>

        <div class="card-body">
          <h4 class="card-title">{{totalCount}} exécution{{totalCount>1 ? 's' : ''}}</h4>
          <div class="table-responsive">
            <table class="table table-striped table-bordered selection-deletion-row" >
              <thead >
              <tr>
                <th  style="width: 10% !important; min-width: 150px;">Début</th>
                <th  style="width: 10% !important;min-width: 150px;" >Fin</th>
                <th  style="width: 7% !important;min-width: 115px;">Durée</th>
                <th  style="width: auto !important;min-width: 400px;">Détails</th>
                <th  style="width: 7% !important;min-width: 110px;">Statut</th>
                <th  style="width: 7% !important;min-width: 150px;">Lancement</th>
                <th  style="width: 7% !important;">Logs</th>
                <th style="width: 14% !important;min-width: 220px;">Données</th>
              </tr>
              </thead>
              <tbody *ngIf="cronExecutionList?.length != 0" >
              <tr *ngFor="let cronExecution of cronExecutionList" >
                <td >{{cronExecution.startDateTime  | date:'dd/MM/YYYY HH:mm:ss'}}</td>
                <td >
                  <div *ngIf='cronExecution.endDateTime !== null'>{{cronExecution.endDateTime  | date:'dd/MM/YYYY HH:mm:ss'}}</div>
                  <div *ngIf='cronExecution.endDateTime == null'>-</div>
                </td>
                <td>
                  {{getDiffDays(cronExecution.endDateTime,cronExecution.startDateTime)}}
                </td>
                <td >{{cronExecution.details}}</td>
                <td >
                  <div *ngIf='cronExecution.endDateTime !== null'>
                    <span class="badge bg-{{cronExecution.isError ? 'danger' : (cronExecution.isWarning ? 'warning' : 'success')}}">
                      {{cronExecution.isError ? 'En erreur' : (cronExecution.isWarning ? 'En warning' : 'Succès')}}
                    </span>
                  </div>
                  <div *ngIf='cronExecution.endDateTime == null'>
                    <span class="badge bg-black">
                      En cours
                    </span>
                  </div>

                </td>
                <td >
                    <span class="badge bg-{{cronExecution.isAutomatic ? 'success' : 'info'}}">
                      {{cronExecution.isAutomatic ? 'Automatique' : 'Manuel'}}
                    </span>
                    <div *ngIf="cronExecution.userName" style="position: relative;bottom: -5px;">
                      {{cronExecution.userName}}
                    </div>
                </td>
                <td style="text-align: center">
                  <i class="ft-activity" (click)="getSteps(cronExecution.id)" style="cursor: pointer"></i>
                </td>
                <td style="font-size: 12px">
                  <div *ngIf='cronExecution.data1.length'><span *ngIf='cron.executionData1Label?.length'>{{cron.executionData1Label}} : </span>{{cronExecution.data1}}</div>
                  <div *ngIf='cronExecution.data2.length'><span *ngIf='cron.executionData2Label?.length'>{{cron.executionData2Label}} : </span>{{cronExecution.data2}}</div>
                  <div *ngIf='cronExecution.data3.length'><span *ngIf='cron.executionData2Label?.length'>{{cron.executionData3Label}} : </span>{{cronExecution.data3}}</div>
                </td>
              </tr>
              </tbody>
              <tbody *ngIf="cronExecutionList?.length == 0" >
              <tr>
                <td class="no-data-available" colspan="8" >Aucune exécution</td>
              </tr>
              </tbody>
            </table>
            <div *ngIf="pageCount > 1">
              <ngb-pagination [collectionSize]="totalCount" [pageSize]="pageSize" (pageChange)="paginate($event)" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--/ Kick start -->
  </div>
</div>
