import { Component, OnInit } from '@angular/core';
import {TokenService} from '../../shared/services/token.service';
import {EditoService} from '../../shared/services/edito/edito.service';
import {Title} from '@angular/platform-browser';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  server = environment.server
  constructor(public tokenService: TokenService,private editoService: EditoService, private title: Title) { }

  ngOnInit(): void {
    if(!this.tokenService.getToken()){
      this.editoService.redirectToLogin();
    }
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Tableau de bord - Maxula travel");
  }

}
