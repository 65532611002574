<div class="modal-header">
  <h4 class="modal-title">Transaction XFT - Requête / Réponse</h4>
  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <div *ngIf="!showHeader">
    <label for="xftRequestTree">
      Requête XML :
      <a *ngIf="(xftTransactionLogRqRs.requestFile ?? '').length" href="{{ xftTransactionLogRqRs.requestFile ?? '' }}"  title="Télécharger"  target="_blank" style="margin-left: 10px">
        <i class="ft-download"></i>
      </a>
      <a *ngIf="(xftTransactionLogRqRs.requestView ?? '').length" href="{{ xftTransactionLogRqRs.requestView ?? '' }}"  title="Ouvrir" target="_blank" style="margin-left: 20px">
        <i class="ft-external-link"></i>
      </a>
    </label>
    <span class="" style="font-size: 12px;float: right;cursor: pointer;font-style: italic; text-decoration: underline;" (click)="getApiLog()" >Log API</span>
    <span class="" style="font-size: 12px;float: right;cursor: pointer;font-style: italic; text-decoration: underline;margin-right: 15px" (click)="showHeaders(true)">Headers</span>

    <div class="xml-content">
      <textarea id="xftRequestTree" #xftRequest readonly>{{ (xftTransactionLogRqRs.request ?? '')  | xmlFormatter }}</textarea>
      <span class="input-group-addon copy-xml" (click)="copyXml(xftRequest.value)"><i class="ft-copy"></i></span>
    </div>

    <label for="xftResponseTree">
      Réponse XML:
      <a *ngIf="(xftTransactionLogRqRs.responseFile ?? '').length" href="{{ xftTransactionLogRqRs.responseFile ?? '' }}" title="Télécharger"  target="_blank" style="margin-left: 10px">
        <i class="ft-download"></i>
      </a>
      <a *ngIf="(xftTransactionLogRqRs.responseView ?? '').length" href="{{ xftTransactionLogRqRs.responseView ?? '' }}" title="Ouvrir" target="_blank" style="margin-left: 20px">
        <i class="ft-external-link"></i>
      </a>
    </label>
    <div class="xml-content">
      <textarea  id="xftResponseTree" #xftResponse readonly>{{ (xftTransactionLogRqRs.response ?? '')  | xmlFormatter }}</textarea>
      <span class="input-group-addon copy-xml" (click)="copyXml(xftResponse.value)"><i class="ft-copy"></i></span>
    </div>
  </div>
  <div *ngIf="showHeader">
    <label for="xftRequestHeader">
      Header requête :
    </label>
    <span class="" style="font-size: 12px;float: right; cursor: pointer;font-style: italic; text-decoration: underline;" (click)="showHeaders(false)">Requête / Réponse</span>
    <div class="xml-content">
      <textarea id="xftRequestHeader" #xftRequestHeader readonly>{{ (requestHeader ?? '')   }}</textarea>
      <span class="input-group-addon copy-xml" (click)="copyXml(xftRequestHeader.value)"><i class="ft-copy"></i></span>
    </div>

    <label for="xftResponseHeader">
      Header réponse :
    </label>
    <div class="xml-content">
      <textarea  id="xftResponseHeader" #xftResponseHeader readonly>{{ (responsetHeader ?? '') }}</textarea>
      <span class="input-group-addon copy-xml" (click)="copyXml(xftResponseHeader.value)"><i class="ft-copy"></i></span>
    </div>
  </div>
</div>
