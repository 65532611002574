import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable, Subscription} from 'rxjs';
import {NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilsService} from '../../shared/services/utils.service';
import {NotificationService} from '../../shared/services/notification.service';
import {CronLogService} from '../../shared/services/log/cron-log.service';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {FileGenerationService} from '../../shared/services/midOffice/file-generation.service';
import {GenerationHourPipe} from '../../shared/pipes/generation-hour.pipe';
import * as moment from 'moment';
import {CronWorkerService} from '../../shared/services/cron-worker/cron-worker.service';
import {CronLogDetailComponent} from '../../cron/cron-log-detail/cron-log-detail.component';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Title} from '@angular/platform-browser';
import {shareReplay, tap} from 'rxjs/operators';
import {XftUserService} from '../../shared/services/midOffice/xft-user.service';
import {PadPipe} from '../../shared/pipes/pad.pipe';
import {ActivatedRoute} from '@angular/router';
import {TokenService} from '../../shared/services/token.service';
import {environment} from '../../../environments/environment';



@Component({
  selector: 'app-file-generation-monitoring',
  templateUrl: './file-generation-monitoring.component.html',
  styleUrls: ['./file-generation-monitoring.component.scss']
})
export class FileGenerationMonitoringComponent implements OnInit, OnDestroy {
  subscription !: Subscription;
  fileGenerationListOriginal: any = []
  fileGenerationList: any = []
  fileGenerationLogList: any = []
  fileGenerationLog: any = []
  fileGenerationRecap: any
  generationHours: Array<string> = [];
  generationHoursList: Array<string> = [];
  nowHour :number
  nowHourLong :string

  fileTypeList$: Observable<any[]>;
  brandList$: Observable<any[]>;
  xftUserList$: Observable<any[]>;
  fileType: string|null = 'cache-price'; // cache-price ceto
  brand: string|null = 'maxula';
  requesterCode:number|null = null;
  date: NgbDateStruct;
  maxDate: NgbDateStruct;
  statusList = [
    { value: true, name: 'Actif' },
    { value: false, name: 'Inactif' },
  ];
  loadingBrand: boolean = true;
  loadingFileType: boolean = true;
  loadingXftUser: boolean = true;
  searchEngineHidden: boolean = (window.innerWidth < 1200 )
  getFileGenerationsSubscription: Subscription
  getCronLogSubscription: Subscription
  scheduleFileGenerationSubscription: Subscription
  now
  updating = true;
  updatingRecap = true;
  isToday = true;
  refreshDuration: number =  59;
  counter: number = this.refreshDuration;
  timer;
  constructor(private fileGenerationService: FileGenerationService,private parameterService: ParameterService,private route: ActivatedRoute,public tokenService: TokenService,private xftUserService: XftUserService,private cronLogService: CronLogService,private cronWorkerService: CronWorkerService,private title: Title,private notifyService : NotificationService,private utilsService : UtilsService,private modalService: NgbModal,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) {

  }

  ngOnInit(): void {
    this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Suivi des générations fichiers XFT - Maxula Travel");
    this.date = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};
    this.maxDate = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};

    this.fileTypeList$ = this.parameterService.getFileType().pipe(tap(() => this.loadingFileType = false),shareReplay(1));
    this.brandList$ = this.parameterService.getBrands().pipe(tap(() => this.loadingBrand = false),shareReplay(1));
    this.xftUserList$ = this.xftUserService.getAllXftUsers().pipe(tap(() => this.loadingXftUser = false),shareReplay(1));

    this.generationHours = Array(24).fill(1).map((x,i)=>new GenerationHourPipe().transform(i));
    for (const hour of this.generationHours) {
      this.generationHoursList[hour] = {
        automatic: null,
        manual:[],
        withGeneration:false
      }
    }
    this.nowHour = this.now.getHours();
    this.nowHourLong = new PadPipe().transform(this.now.getHours());
    this.route.params.subscribe(params => {
      this.brand = params['brand'] ?? this.brand;
      this.fileType = params['fileType'] ?? this.fileType;
    });
    this.searchFileGeneration();

    const breadcrumbs  =  [
      {
        label: 'Accueil',
        url: '/'
      },
      {
        label: 'Générations fichiers',
        url: ''
      },
      {
        label: 'Suivi des générations',
        url: ''
      }
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
    this.startCountdown()

  }
  ngOnDestroy() {
    if (this.getFileGenerationsSubscription) {
      this.getFileGenerationsSubscription.unsubscribe();
    }
    if (this.getCronLogSubscription) {
      this.getCronLogSubscription.unsubscribe();
    }
    if (this.scheduleFileGenerationSubscription) {
      this.scheduleFileGenerationSubscription.unsubscribe();
    }
    clearInterval(this.timer);
  }
  searchFileGeneration() {
    this.updating = true;
    this.updatingRecap = true;
    this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.nowHour = this.now.getHours();
    this.nowHourLong = new PadPipe().transform(this.now.getHours());
    this.utilsService.loadingShow()
    this.isToday = this.now.getFullYear()+''+(this.now.getMonth()+1)+''+this.now.getDate() == this.date.year+''+this.date.month+''+this.date.day;
    this.getFileGenerationsSubscription = this.fileGenerationService.getFileGenerations(this.fileType, this.brand, this.requesterCode, true).subscribe(
        fileGenerationList => {
          this.fileGenerationListOriginal = [];
          // Boucle sur la liste des générations
          let fileGeneration;
          let generationData;
          for (let item in fileGenerationList) {
            fileGeneration = fileGenerationList[item];
            // Ajouter la génération
            generationData = {
              id: fileGeneration.id,
              requesterCode: fileGeneration.requesterCode,
              name: fileGeneration.requesterCode + ' - ' + fileGeneration.requesterName + ' ('+fileGeneration.backofficeCode+')',
              generations:structuredClone(this.generationHoursList),

              /*automatic: structuredClone(this.generationHoursList),
              manual: []*/
            }
            // Boucle sur les heures programmées
            for (let h in fileGeneration.schedule) {
              let hInt = Number(fileGeneration.schedule[h].split(':')[0])
              generationData.generations[fileGeneration.schedule[h]].automatic = {
                status:hInt >= this.nowHour && this.isToday ? 'PLANNED' : 'ERROR',
                badge:hInt >= this.nowHour && this.isToday ? 'dark' : 'danger',
                log:null,
              }
              generationData.generations[fileGeneration.schedule[h]].withGeneration = true;
            }
            this.fileGenerationListOriginal.push(generationData);
          }
          // Get cron logs & update
          this.updateFileGenerationLog();
        },
        error => {
          this.utilsService.loadingHide()
          this.notifyService.showError(error.error.message ?? (error.message ?? ''), '')
        }
    )
  }
  updateFileGenerationLog(){
    this.updating = true;
    this.utilsService.loadingShow()
    this.fileGenerationList = structuredClone(this.fileGenerationListOriginal);
    // GET GENERATION LOG
    let cronCode = 'xft-' + this.fileType + '-' + this.brand;
    this.getCronLogSubscription = this.cronLogService.getCronLog(cronCode, this.utilsService.datePickerToDateTime(this.date), this.utilsService.datePickerToDateTime(this.date, false), null, null, this.brand, null, null, 1, 99999).subscribe(
        fileGenerationLogList => {
          let log;
          let generationType;
          let status;
          let badge;
          let requesterCode;
          let date;
          let hour;

          for (let item in fileGenerationLogList.cronExecutionLog) {
            log = fileGenerationLogList.cronExecutionLog[item];
            requesterCode = log.data2
            generationType = log.isAutomatic ? 'automatic' : 'manual'
            date = moment(log.startDateTime, 'YYYY-MM-DD\Thh:mm:ss').toDate()
            hour = new GenerationHourPipe().transform(date.getHours());
            status = this.getGenerationBadge(log).status
            badge = this.getGenerationBadge(log).badge
            for (const fileGeneration of this.fileGenerationList) {
              if (fileGeneration.requesterCode == requesterCode) {
                if (generationType == 'automatic' && fileGeneration.generations[hour].automatic !== undefined) {
                  try {
                    fileGeneration.generations[hour].automatic.status = status
                    fileGeneration.generations[hour].automatic.badge = badge
                    fileGeneration.generations[hour].automatic.log = log
                  } catch (e) {

                  }

                }
                if (generationType == 'manual') {
                  // Si pas d'ancienne génération à cette heure > créer la structure
                  if(fileGeneration.generations[hour].manual === undefined) {
                    fileGeneration.generations[hour].manual=[];
                  }
                  fileGeneration.generations[hour].manual.push ({
                    status:status,
                    badge:badge,
                    log:log,
                  });
                  fileGeneration.generations[hour].withGeneration = true;
                }
              }
            }
          }
          this.updateFileGenerationRecap();
          this.utilsService.loadingHide()
          this.updating = false;
        },
        error => {
          this.utilsService.loadingHide()
          this.notifyService.showError(error.error.message ?? (error.message ?? ''), '')
        }
    )
    this.resetCounter();
  }
  getGenerationBadge(log){
    let status;
    let badge;
    let date = moment(log.startDateTime, 'YYYY-MM-DD\Thh:mm:ss').toDate()
    let hInt = Number(date.getHours())
    if (log.endDateTime !== null) {
      status = 'OK'
      badge = 'success'
      // GENERATION COMPLETED BUT IN ERROR
      if (log.isError) {
        status = 'ERROR'
        badge = 'danger'
      }
      // GENERATION COMPLETED BUT IN WARNING
      if (log.isWarning) {
        status = 'WARNING'
        badge = 'warning'
      }
    } else {
      // GENERATION NOT COMPLETED AND 1 HOUR PASSED
      if (hInt < this.nowHour) {
        status = 'ERROR'
        badge = 'danger'
      } else {
        // GENERATION QUEUED
        if (log.startTimeStamp == log.updateTimeStamp) {
          status = 'QUEUED'
          badge = 'info'
        } else {
          // GENERATION IN PROGRESS
          status = 'RUNNING'
          badge = 'in-progress'
        }
      }
    }
    return {status:status,badge:badge}
  }

  schedule(id: number) {
    this.scheduleFileGenerationSubscription = this.cronWorkerService.scheduleFileGeneration(id).subscribe(
      () => {
        this.notifyService.showSuccess('Génération ajoutée à la fil d\'attente',"")
      },
      error => {
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")

      }
    )
    setTimeout(() =>{
      this.searchFileGeneration();
    }, 2000);

  }
  getSteps(code:string,id:number) {
    if(code && id) {
      const modalRef = this.modalService.open(CronLogDetailComponent,{ size: 'lg'});
      modalRef.componentInstance.id = id;
      modalRef.componentInstance.code = code;
    } else  {
      this.notifyService.showError('Aucun log génération', '')
    }


  }
  updateFileGenerationRecap(){
    this.updatingRecap = true;
    this.fileGenerationRecap = {
      'automatic' : {'OK':0,'ERROR':0,'WARNING':0,'RUNNING':0,'QUEUED':0,'PLANNED':0,},
      'manual' : {'OK':0,'ERROR':0,'WARNING':0,'RUNNING':0,'QUEUED':0},
      'requesters' : {},
      'hours':{}

    };
    let requesterCode;
    for (let user in this.fileGenerationList) {
      requesterCode = this.fileGenerationList[user].requesterCode
      if (!this.fileGenerationRecap[user]) {
        this.fileGenerationRecap.requesters[requesterCode] = {'TOTAL': 0, 'OK': 0, 'GENERATED': 0}
      }
      for (let hour in this.fileGenerationList[user]['generations']) {
        if (this.fileGenerationList[user]['generations'][hour]['automatic'] !== null) {
          if(!this.fileGenerationRecap.hours[hour]) {
            this.fileGenerationRecap.hours[hour] = {'TOTAL': 0, 'OK': 0, 'GENERATED': 0}
          }
          this.fileGenerationRecap.hours[hour].TOTAL ++;
          if ((this.fileGenerationList[user]['generations'][hour]['automatic']['status'] ?? '') == 'OK') {
            this.fileGenerationRecap.automatic.OK = (this.fileGenerationRecap.automatic.OK ?? 0) + 1
            this.fileGenerationRecap.requesters[requesterCode].OK ++;
            this.fileGenerationRecap.requesters[requesterCode].GENERATED ++;
            this.fileGenerationRecap.hours[hour].OK ++;
            this.fileGenerationRecap.hours[hour].GENERATED ++;
          }
          if ((this.fileGenerationList[user]['generations'][hour]['automatic']['status'] ?? '') == 'ERROR') {
            this.fileGenerationRecap.automatic.ERROR = (this.fileGenerationRecap.automatic.ERROR ?? 0) + 1
            this.fileGenerationRecap.requesters[requesterCode].GENERATED ++;
            this.fileGenerationRecap.hours[hour].GENERATED ++;
          }
          if ((this.fileGenerationList[user]['generations'][hour]['automatic']['status'] ?? '') == 'RUNNING') {
            this.fileGenerationRecap.automatic.RUNNING = (this.fileGenerationRecap.automatic.RUNNING ?? 0) + 1
          }
          if ((this.fileGenerationList[user]['generations'][hour]['automatic']['status'] ?? '') == 'QUEUED') {
            this.fileGenerationRecap.automatic.QUEUED = (this.fileGenerationRecap.automatic.QUEUED ?? 0) + 1
          }
          if (this.fileGenerationList[user]['generations'][hour]['automatic']['status'] == 'PLANNED') {
            this.fileGenerationRecap.automatic.PLANNED = (this.fileGenerationRecap.automatic.PLANNED ?? 0) + 1
          }
          if (this.fileGenerationList[user]['generations'][hour]['automatic']['status'] == 'WARNING') {
            this.fileGenerationRecap.automatic.WARNING = (this.fileGenerationRecap.automatic.WARNING ?? 0) + 1
            this.fileGenerationRecap.requesters[requesterCode].GENERATED ++;
            this.fileGenerationRecap.hours[hour].GENERATED ++;
          }

          this.fileGenerationRecap.requesters[requesterCode].TOTAL ++;
        }

        for (let manualGeneration in this.fileGenerationList[user]['generations'][hour]['manual']) {
          if (this.fileGenerationList[user]['generations'][hour]['manual'][manualGeneration]['status'] == 'OK') {
            this.fileGenerationRecap.manual.OK = (this.fileGenerationRecap.manual.OK ?? 0) + 1
          }
          if (this.fileGenerationList[user]['generations'][hour]['manual'][manualGeneration]['status'] == 'ERROR') {
            this.fileGenerationRecap.manual.ERROR = (this.fileGenerationRecap.manual.ERROR ?? 0) + 1
          }
          if (this.fileGenerationList[user]['generations'][hour]['manual'][manualGeneration]['status'] == 'RUNNING') {
            this.fileGenerationRecap.manual.RUNNING = (this.fileGenerationRecap.manual.RUNNING ?? 0) + 1
          }
          if (this.fileGenerationList[user]['generations'][hour]['manual'][manualGeneration]['status'] == 'QUEUED') {
            this.fileGenerationRecap.manual.QUEUED = (this.fileGenerationRecap.manual.QUEUED ?? 0) + 1
          }
          if (this.fileGenerationList[user]['generations'][hour]['manual'][manualGeneration]['status'] == 'WARNING') {
            this.fileGenerationRecap.manual.WARNING = (this.fileGenerationRecap.manual.WARNING ?? 0) + 1
          }
        }

      }
    }
    for (let hour in this.fileGenerationRecap.hours) {
      let hInt = Number(hour.split(':')[0])
      if(hInt > this.nowHour && this.isToday ) {
        this.fileGenerationRecap.hours[hour] = {'TOTAL': 0, 'OK': 0, 'GENERATED': 0};
      }
    }

    /*generationData.generations[fileGeneration.schedule[h]].automatic = {
      status:hInt >= this.nowHour && this.isToday ? 'PLANNED' : 'ERROR',*/
    this.updatingRecap = false;
  }

  customSearchClient(term: string, item) {
    term = term.toLocaleLowerCase();
    return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
  }
  showHideSearchEngine(){
    this.searchEngineHidden = !this.searchEngineHidden;
  }
  resetCounter() {
    this.counter = this.refreshDuration;
  }
  startCountdown() {
    this.counter = this.refreshDuration
    this.timer = setInterval(() => {
      this.counter--;

      if (this.counter === 0) {
        // Réinitialise le compteur à 60 lorsque le décompte atteint 0
        this.counter = this.refreshDuration;
        this.updateFileGenerationLog()
      }
    }, 1000); // Décrémente toutes les secondes (1000 millisecondes)
  }
  getConicGradient(): string {
    return `conic-gradient(
      #FFFFFF 0% calc(100% - ${(this.counter / this.refreshDuration) * 100}%),
      transparent calc(100% - ${(this.counter / this.refreshDuration) * 100}%) 100%
    )`;
  }
}
