<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >Générations fichiers</h6>
                <i class="ft-plus-circle btn-add" (click)="addEdit()" title="Nouvelle génération" *ngIf="tokenService.checkRole([])"></i>
            </div>
            <div class="card-content">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form (ngSubmit)="getFileGenerations();">
                        <div class="row" style="display: flex!important;">
                            <!--Type de fichier-->
                            <div class="col-lg-4">
                                <div class="mb-3">
                                    <label>
                                        Type de fichier
                                        <ng-select [disabled]="planner" [loading]="loadingFileType" [notFoundText]="'Aucun type de fichier'" [markFirst]="false" [searchable]="(fileTypeList$ | async)?.length  > 10" [items]="fileTypeList$ | async" id="fileType" labelForId="fileType" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Type de fichier" [(ngModel)]="fileType">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Marque -->
                            <div class="col-lg-2" [hidden]="true">
                                <div class="mb-3">
                                    <label>
                                        Marque
                                        <ng-select [disabled]="planner" [loading]="loadingBrand" [notFoundText]="'Aucune marque'" [markFirst]="false" [searchable]="(brandList$ | async)?.length  > 10" [items]="brandList$ | async" id="brand" labelForId="brand" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Marque" [(ngModel)]="brand">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- Requester code -->
                            <div class="col-lg-4">
                                <div class="mb-3">
                                    <label>
                                        Requester code
                                        <div class="form-group">
                                            <ng-select [disabled]="planner" [loading]="loadingXftUser" [notFoundText]="'Aucun client'" [markFirst]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [searchFn]="customSearchClient" [items]="xftUserList$ | async" id="clientCode" labelForId="clientCode" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Requester code" [(ngModel)]="requesterCode">
                                                <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Etat -->
                            <div class="col-md-2">
                                <div class="form-floating mb-3">
                                    <label>
                                        Etat
                                        <ng-select [notFoundText]="'Aucun état'" [markFirst]="false" [searchable]="false" [items]="statusList" [ngModelOptions]="{standalone: true}" id="status" labelForId="status" bindValue="value" bindLabel="name" placeholder="Tous" [(ngModel)]="status">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <!-- PLANNER -->
                            <div class="col-md-2" *ngIf="tokenService.checkRole([])">
                                <div class="custom-switch mb-3">
                                    <br>
                                    <input [disabled]="fileType == null || brand == null || requesterCode != null"  (change)="getFileGenerations()"  type="checkbox" id="planificateur-switch" name="status" ngModel [(ngModel)]="planner" class="custom-control-input form-control">
                                    <label for="planificateur-switch" class="custom-control-label mr-1">
                                        <span>Planificateur</span>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-primary btn-float-right" type="submit" id="search">
                                    <i class="ft-search mr-1"></i>Rechercher
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="card-body">

                    <h4 class="card-title">{{totalCount}} génération{{totalCount>1 ? 's' : ''}}</h4>
                    <div class="table-responsive">
                        <table class="table table-striped {{planner ? 'tbl-planner' : ''}}">
                            <thead class="thead-light">
                            <tr>
                                <th [hidden]="planner" style="width: 5% !important;min-width: 50px;">ID</th>
                                <th  style="vertical-align: text-bottom;width: 15% !important; {{planner ? 'min-width:200px;' : 'min-width:150px;'}}">
                                    <span [hidden]="planner">Ficher</span>
                                    <span [hidden]="!planner">Requester</span>

                                </th>
                                <th [hidden]="planner" style="width: 10% !important;min-width: 90px;" >Marque</th>
                                <th [hidden]="planner" style="width: 20% !important;min-width: 240px;">Requester</th>
                                <th [hidden]="planner" style="width: 8% !important;min-width: 100px;">Réf BO</th>
                                <th  style="width: auto !important;{{planner ? 'min-width: 1020px;' : 'min-width:300px;'}}">
                                    Horaires
                                    <div [hidden]="!planner">
                                        <div class="" *ngFor="let hour of generationHours" style=" float: left; text-align: center;margin-right: 0.375rem !important;width: 30px;">
                                            <span style="font-size: 8px;line-height: 0;">{{hour|generationHour}}</span>
                                            <span [ngClass]="{
                                                  'bg-light-secondary': formatNumber(countGenerationsGyHour(hour|generationHour)) == 0,
                                                  'bg-success': formatNumber(countGenerationsGyHour(hour|generationHour)) > 0 && formatNumber(countGenerationsGyHour(hour|generationHour)) < alertNbGeneration,
                                                  'bg-warning': formatNumber(countGenerationsGyHour(hour|generationHour)) >= alertNbGeneration
                                                }"  class="badge  badge-count  mb-1 mr-1" style="margin-right: 0!important;" >
                                                {{formatNumber(countGenerationsGyHour(hour|generationHour))}}
                                            </span>
                                        </div>
                                        <div  style=" float: left; text-align: center;margin-right: 0.375rem !important;">
                                            <span style="font-size: 8px;"></span><br>
                                            <span class="  badge-count  mb-1 mr-1" style="margin-right: 0!important;cursor: pointer" >
                                                <i class="ft-trash" style="font-size: 12px !important;"  (click)="clearGeneration()"></i>
                                            </span>
                                        </div>
                                        <br>
                                        <span class="badge bg-info   mb-1 mr-1"  style="width: 95px;">
                                            {{countGenerations()}} génération{{(countGenerations()) ? 's' : ''}}
                                        </span>
                                    </div>
                                </th>
                                <th  style="width: 5% !important;vertical-align: text-bottom;">Active</th>
                                <th style="width: 10% !important; min-width: 150px"></th>
                            </tr>
                            </thead>
                            <tbody *ngIf="fileGenerationList?.length != 0">
                            <tr *ngFor="let fileGeneration of fileGenerationList"  >
                                <td [hidden]="planner">{{fileGeneration.id}}</td>
                                <td >
                                    <div [hidden]="!planner" style="white-space: nowrap;">
                                        {{fileGeneration.requesterCode}} - {{fileGeneration.requesterName}}<br>
                                    </div>
                                    <span [hidden]="planner">{{fileGeneration.fileTypeName}}</span>
                                    <!--<span [hidden]="!planner">
                                        - {{fileGeneration.brandName}}
                                    </span>-->
                                </td>
                                <td [hidden]="planner">{{fileGeneration.brandName}}</td>
                                <td [hidden]="planner">{{fileGeneration.requesterCode}} - {{fileGeneration.requesterName}}</td>
                                <td [hidden]="planner">{{fileGeneration.backofficeCode}}</td>
                                <td >
                                    <div [hidden]="planner">
                                        <span class="badge bg-light-secondary mb-1 mr-1" *ngFor="let hour of fileGeneration.schedule">
                                          {{hour}}
                                        </span>
                                    </div>
                                    <div [hidden]="!planner">
                                        <span class="" *ngFor="let hour of generationHours" style="width: 70px;">
                                            <span [ngClass]="(fileGeneration.schedule.indexOf(hour|generationHour) > -1) ? 'bg-success' : 'bg-light-secondary'" (click)="updateHour(fileGeneration,hour)"  class="badge badge-count   mb-1 mr-1" style="cursor: pointer" >
                                              {{hour|generationHour:"short"}}
                                            </span>
                                        </span>
                                        <span class=""  style="width: 70px;">
                                            <span class=" badge-count   mb-1 mr-1" style="cursor: pointer" >
                                              <i class="ft-trash" style="font-size: 12px !important;" (click)="clearGeneration(fileGeneration)"></i>
                                            </span>
                                        </span>
                                        <span class="badge bg-info   mb-1 mr-1"  style="width: 95px;">
                                            {{fileGeneration.schedule.length}} génération{{(fileGeneration.schedule.length > 1) ? 's' : ''}}
                                        </span>
                                    </div>
                                </td>
                                <td >
                                    <i *ngIf="fileGeneration.isActive" class="ft-check-circle" style="color: #40C057"></i>
                                    <i *ngIf="!fileGeneration.isActive" class="ft-x-circle" style="color: red"></i>
                                </td>
                                <td style="text-align: right">
                                    <a (click)="addEdit(fileGeneration)" title="Éditer" [hidden]="planner" *ngIf="tokenService.checkRole([])">
                                        <i class="ft-edit-2" style="margin: 5px; color:green;"></i>
                                    </a>
                                    <a (click)="deleteFileGeneration(fileGeneration.id)" title="Supprimer" [hidden]="planner" *ngIf="tokenService.checkRole([])">
                                        <i class="ft-x" style="margin: 5px; color:red;"></i>
                                    </a>
                                    <a (click)="auditLog(fileGeneration.id)" title="Log modification" [hidden]="planner">
                                        <i class="ft-layers" style="margin: 5px; color:grey;"></i>
                                    </a>
                                    <a (click)="schedule(fileGeneration.id)" title="Lancer la génération" [hidden]="planner" *ngIf="tokenService.checkRole(['ROLE_ADMIN'])">
                                        <i class="ft-play" style="margin: 5px; color:#0080f9;"></i>
                                    </a>

                                </td>
                            </tr>
                            <tr [hidden]="!planner" *ngIf="fileGenerationList?.length != 0">
                                <td style="vertical-align: top;"><br>TOTAL</td>
                                <td style="vertical-align: top">
                                    <div class="" *ngFor="let hour of generationHours" style=" float: left; text-align: center;margin-right: 0.375rem !important;">
                                        <span style="font-size: 8px;line-height: 1px;">{{hour|generationHour}}</span><br>
                                        <span [ngClass]="{
                                              'bg-light-secondary': formatNumber(countGenerationsGyHour(hour|generationHour)) == 0,
                                              'bg-success': formatNumber(countGenerationsGyHour(hour|generationHour)) > 0 && formatNumber(countGenerationsGyHour(hour|generationHour)) < alertNbGeneration,
                                              'bg-warning': formatNumber(countGenerationsGyHour(hour|generationHour)) >= alertNbGeneration
                                            }"  class="badge  badge-count  mb-1 mr-1" style="margin-right: 0!important;" >
                                            {{formatNumber(countGenerationsGyHour(hour|generationHour))}}
                                        </span>
                                    </div>
                                    <div  style=" float: left; text-align: center;margin-right: 0.375rem !important;">
                                        <span style="font-size: 8px;"></span><br>
                                        <span class="  badge-count  mb-1 mr-1" style="margin-right: 0!important;cursor: pointer" >
                                            <i class="ft-trash" style="font-size: 12px !important;"  (click)="clearGeneration()"></i>
                                        </span>
                                    </div>
                                    <br>
                                    <span class="badge bg-info   mb-1 mr-1"  style="width: 95px;">
                                        {{countGenerations()}} génération{{(countGenerations()) ? 's' : ''}}
                                    </span>
                                </td>
                                <td colspan="2" style="text-align: center; vertical-align: center">
                                    <button [hidden]="!areArraysDifferent()" (click)="updatePlanning()" class="btn btn-primary waves-effect btn-label waves-light" type="button" style="margin-top: 11px;    margin-bottom: auto;"  >
                                        <i class="ft-save"></i> Enregistrer
                                    </button>
                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="fileGenerationList?.length == 0" >
                            <tr>
                                <td class="no-data-available" colspan="7">Aucune génération</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
