<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >CachePrice check</h6>
            </div>
            <div class="card-content">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form   (ngSubmit)="getCachePrice();">
                        <div class="row" style="display: flex!important;">
                            <!-- Requester code -->
                            <div class="col-lg-4">
                                <div class="mb-3">
                                    <label>
                                        Requester code<span class="red">*</span>
                                        <div class="form-group">
                                            <ng-select [loading]="loadingXftUser" [notFoundText]="'Aucun client'" [markFirst]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [searchFn]="customSearchClient" [items]="xftUserList$ | async" id="clientCode" labelForId="clientCode" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Requester code" [(ngModel)]="requesterCode">
                                                <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                                                    {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                                                </ng-template>
                                            </ng-select>
                                        </div>
                                    </label>
                                </div>
                            </div>
                            <!-- Product Code-->
                            <div class="col-md-2">
                                <div class="form-floating mb-3">
                                    <label for="productCode">Code produit<span class="red">*</span></label>
                                    <input class="form-control" id="productCode" name="productCode" ngModel type="text" [(ngModel)]="productCode" value="">
                                </div>
                            </div>
                        </div>
                        <div class="row blocBtnSearch">
                            <div class="col-md-9">
                                <div class="d-flex justify-content-start product-count"></div>
                            </div>
                            <div class="col-md-3">
                                <div class="d-flex justify-content-end">
                                    <button class="btn btn-primary" type="submit" id="search" [disabled]="productCode == '' || requesterCode == null">
                                        <i class="ft-search mr-1"></i>Rechercher
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="card shadow-none" *ngIf="cachePriceData !== null">
            <div class="card-content">
                <div class="card-header">
                    <h4 class="card-title"></h4>
                </div>
                <div class="card-body">
                    <b>Dernière Génération : </b>
                    {{cachePriceData?.generationDateTime  | date:'dd/MM/YYYY' | dateText}}
                    {{cachePriceData?.generationDateTime  | date:'HH:mm:ss'}}

                    <br>
                    <b>Télécharger le fichier : </b> <a href="{{cachePriceData?.link}}" target="_blank"><i class="ft-download"></i></a>
                    <div *ngIf="(cachePriceData?.availability  | keyvalue).length" >
                        <table class="table table-striped table-bordered selection-deletion-row xft-transaction-log">
                            <thead >
                            <tr>
                                <th style="width: 16% !important; min-width: 190px">Ville de départ</th>
                                <th style="width: 16% !important; min-width: 190px">Date de départ</th>
                                <th style="width: 16% !important; min-width: 190px">Prix TTC</th>
                                <th style="width: 16% !important; min-width: 190px">Taxes</th>
                                <th style="width: 16% !important; min-width: 190px">Prix brochure</th>
                                <th  style="width: auto !important; min-width: 190px">Pension</th>
                            </tr>
                            </thead>
                            <tbody *ngFor="let departureCity of cachePriceData?.availability?.departures  | keyvalue" >
                                <tr *ngFor="let departureDate of departureCity.value  | keyvalue">
                                    <td >{{departureCity.key}}</td>
                                    <td >{{departureDate.key | date:'dd/MM/YYYY' | dateText}}</td>
                                    <td >{{departureDate.value.price ?? '-'}}€</td>
                                    <td >{{departureDate.value.tax ?? '-'}}€</td>
                                    <td >{{departureDate.value.originalPrice ?? '-'}}€</td>
                                    <td >{{cachePriceData?.availability?.mealPlan?.name ?? '-'}}</td>
                                </tr>


                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--/ Kick start -->
    </div>
</div>
