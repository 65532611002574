import { Injectable } from '@angular/core';
import jwt_decode from 'jwt-decode';

const TOKEN_KEY = 'token';

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  userInfos: any = ''
  constructor() { }
  clearToken(): void {
    localStorage.clear();
  }

  public saveToken(token: string): void {
    localStorage.removeItem(TOKEN_KEY);
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return localStorage.getItem(TOKEN_KEY);
  }
  public isExpired(): boolean {
    return  false;
  }

  public getUserInfos(){
    let user = {name:'' ,roles:[]};
    if(!this.isExpired()) {
      try {
        this.userInfos = jwt_decode(this.getToken());
        user.name = this.userInfos.username;
        // If user roles not array => convert to array
        user.roles = this.userInfos.roles;
      } catch {}
    }
    return user;
  }

  checkRole(roles: Array<string>) {
    roles.push('ROLE_SUPERADMIN')
    if(!this.isExpired()) {
      try {
        var roleExist = false;
        this.getUserInfos().roles.forEach(function(role){
          if(roles.map(v => v.toLowerCase()).includes(role.toLowerCase())) {
            roleExist = true;
          }
        });
        return  roleExist;
      } catch (error){
      }
    }

    return false;
  }
}
