<div class="modal-header">
    <h4 class="modal-title">
        Log API

    </h4>
    <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
    <span style="font-weight: 500;float: right;font-size: 14px;" *ngIf="apiLogList.length">
        {{apiLogList.length}} transaction{{apiLogList.length > 1 ? 's' : ''}}
    </span>
    <div class="table-responsive">
        <table class="table table-striped api-transaction-log" aria-describedby="Liste api log">
            <thead class="thead-light">
            <tr>
                <th  style="width: 12% !important;min-width: 160px;">Date & heure</th>
                <th  style="width: 12% !important;min-width: 150px;">Type</th>
                <th  style="width: 12% !important;min-width: 150px;">Transaction</th>
                <th  style="width: 8% !important;min-width: 150px;" >Fournisseur</th>
                <th  style="width: 15% !important;min-width: 150px;">Utilisateur</th>
                <th  style="width: 7% !important;min-width: 85px;">Durée</th>
                <th  style="width: 8% !important;min-width: 90px;">Serveur</th>
                <th  style="width:8% !important;">Status</th>
                <th  style="width:8% !important;">Code</th>
                <th style="width: auto !important;"></th>
            </tr>
            </thead>
            <tbody *ngFor="let apiLog of apiLogList">
            <tr   (click)="getApiRqRs(apiLog)"  class="transaction-{{apiLog.isError ? 'error' :  (apiLog.isWarning ?'warning' : 'success')}} {{apiLogId == apiLog.id ? 'current-api-log' : ''}}">
                <td>
                    <i *ngIf="apiLog.toPurge" class="fa fa-circle fa-purge-info" style="margin: 5px; color:red;" title="A purger"></i>
                    <i *ngIf="!apiLog.toPurge" class="fa fa-circle fa-purge-info" style="margin: 5px; color:green;" title="A garder"></i>

                    {{apiLog.creationDate  | date:'dd/MM/YYYY' | dateText}}
                    {{apiLog.creationDate  | date:'HH:mm:ss'}}
                </td>
                <td >{{apiLog.type}}</td>
                <td >{{apiLog.action}}<span *ngIf="apiLog.methode?.length"> ({{apiLog.methode}})</span></td>
                <td >{{apiLog.provider}}</td>
                <td >{{apiLog.consumer}}</td>
                <td ><span class="badge badge-pill bg-light-success  {{apiLog.msDuration | durationBadge: 1000 }}">{{apiLog.msDuration | msDuration }}</span></td>
                <td >{{apiLog.server}}</td>
                <td >
                    <span class="badge badge-{{apiLog.isError ? 'danger' :  (apiLog.isWarning ?'warning' : 'success')}} ">
                      {{apiLog.isError ? 'Erreur' :  (apiLog.isWarning ?'Alerte' : 'OK')}}
                    </span>
                </td>
                <td >
                    {{apiLog.statusCode}}
                </td>
                <td style="text-align: center" >
                    <a   title="Détails"><!--(click)="getApiRqRs(apiLog)"-->
                        <i class="ft-code" style="margin: 5px; color:cornflowerblue;"></i>
                    </a>

                </td>
            </tr>
            <tr *ngIf="idLogApiDetail == apiLog.id">
                <td colspan="100%">
                    <ng-template #requestTemplate>
                        <label for="requestApi">
                            Requête :
                            <a *ngIf="(logRqRs.requestFile ?? '').length" href="{{ logRqRs.requestFile ?? '' }}"   target="_blank">
                                <i class="ft-download"></i>
                            </a>
                        </label>

                        <div class="textarea-content txt-content" *ngIf="!showSql">
                            <textarea id="requestApi" #textareaRequest readonly>{{ request }}</textarea>
                            <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaRequest.value)"><i class="ft-copy"></i></span>
                        </div>
                        <pre class="textarea-content sql-content" style="margin-bottom: 20px;white-space: pre-wrap;" *ngIf="showSql">{{requestSql}}</pre>
                    </ng-template>
                    <ng-template #responseTemplate>
                        <label for="responseApi">
                            Réponse :
                            <a *ngIf="(logRqRs.responseFile ?? '').length" href="{{ logRqRs.responseFile ?? '' }}" target="_blank">
                                <i class="ft-download"></i>
                            </a>
                        </label>

                        <div class="textarea-content txt-content" *ngIf="!showSql">
                            <textarea id="responseApi" #textareaResponse readonly>{{ response }}</textarea>
                            <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaResponse.value)"><i class="ft-copy"></i></span>
                        </div>
                        <div class="textarea-content sql-content" *ngIf="showSql" style="width: 100%;    overflow: auto;">
                            <pre style="text-align: center" *ngIf="!responseSql.length">Aucun résultat</pre>
                            <table class="table table-striped sql-table" >
                                <tr *ngFor="let ligne of responseSql; let isFirst = first">
                                    <ng-container *ngIf="isFirst; else skip">
                                        <th *ngFor="let keyValuePair of ligne | keyvalue">{{ keyValuePair.key }}</th>
                                    </ng-container>
                                    <ng-template #skip></ng-template>

                                </tr>
                                <tr *ngFor="let ligne of responseSql; let isFirst = first">
                                    <td *ngFor="let keyValuePair of ligne | keyvalue"><pre>{{ keyValuePair.value  }}</pre></td>
                                </tr>
                            </table>
                        </div>
                    </ng-template>
                    <span class="" style="float: right;cursor: pointer;font-style: italic; text-decoration: underline;" (click)="showHeaders(!showHeader)">
                        {{showHeader ? 'Requête / Réponse' : 'Headers'}}
                        <!--<label style="float: right; cursor: pointer;font-style: italic; text-decoration: underline;width: auto" (click)="showHeaders(false)">Requête / Réponse</label>-->
                    </span>
                    <span class="" *ngIf="isSqlResponse && !showHeader" style="float: right;cursor: pointer;font-style: italic; margin-right: 15px; text-decoration: underline;" (click)="showText(!showSql)">
                      {{showSql ? 'Text' : 'Sql'}}
                    </span>
                    <div  *ngIf="!showHeader && showSql">

                        <ng-container *ngTemplateOutlet="requestTemplate"></ng-container>
                    </div>
                    <div  *ngIf="!showHeader && showSql">
                        <ng-container *ngTemplateOutlet="responseTemplate"></ng-container>
                    </div>

                    <table class="xft-log-api-log-detail">
                        <tr *ngIf="!showHeader && !showSql">
                            <td style="padding-top: 0;">
                                <ng-container *ngTemplateOutlet="requestTemplate"></ng-container>
                            </td>

                            <td style="padding-top: 0;">

                                <ng-container *ngTemplateOutlet="responseTemplate"></ng-container>
                            </td>
                        </tr>
                        <tr  *ngIf="showHeader">
                            <td >
                                <label>
                                    Header requête :
                                </label>
                                <div class="textarea-content">
                                    <textarea id="requestHeader"  #textareaRequestHeader readonly>{{ requestHeader }}</textarea>
                                    <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaRequestHeader.value)"><i class="ft-copy"></i></span>
                                </div>
                            </td>
                            <td>
                                <label style="width: auto">
                                    Header réponse :
                                </label>


                                <div class="textarea-content">
                                    <textarea #textareaResponseHeader readonly>{{ responseHeader }}</textarea>
                                    <span class="input-group-addon copy-rq-rs" (click)="copyRqRs(textareaResponseHeader.value)"><i class="ft-copy"></i></span>
                                </div>
                            </td>
                        </tr>
                        <tr *ngIf="logApiDetail.endPoint.length">
                            <td colspan="2">EndPoint : {{logApiDetail.endPoint}}</td>
                        </tr>
                    </table>

                </td>
            </tr>
            </tbody>

            <tr  *ngIf="apiLogList.length">
                <td colspan="2">

                </td>
                <td></td>
                <td></td>
                <td style="text-align: right">Total exécution :</td>
                <td ><span class="badge badge-pill bg-light-success">{{this.totalDuration | msDuration }}</span></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tbody *ngIf="apiLogList?.length == 0" >
            <tr>
                <td class="no-data-available" colspan="11">Aucune transaction</td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
