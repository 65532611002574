import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {NotificationService} from '../../shared/services/notification.service';
import {UtilsService} from '../../shared/services/utils.service';
import {XftUserService} from '../../shared/services/midOffice/xft-user.service';
import {FileGenerationService} from '../../shared/services/midOffice/file-generation.service';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Title} from '@angular/platform-browser';
import {shareReplay, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-cache-price-check',
  templateUrl: './cache-price-check.component.html',
  styleUrls: ['./cache-price-check.component.scss']
})
export class CachePriceCheckComponent implements OnInit {

    cachePriceData: any =null
    brandList$: Observable<any[]>;
    xftUserList$: Observable<any[]>;
    brand:string|null = 'maxula';
    requesterCode:number|null  = null;
    productCode:string  = '';
    searchEngineHidden: boolean = false
    loadingXftUser: boolean = true;
    constructor(private fileGenerationService: FileGenerationService,private parameterService: ParameterService,private title: Title,private notifyService : NotificationService,private utilsService : UtilsService,private xftUserService: XftUserService,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) {}
    ngOnInit(): void {
        this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "CachePrice check - Maxula Travel");
        this.brandList$ = this.parameterService.getBrands();
        this.xftUserList$ = this.xftUserService.getAllXftUsers().pipe(tap(() => this.loadingXftUser = false),shareReplay(1));
        const breadcrumbs  =  [
            {
                label: 'Accueil',
                url: '/'
            },
            {
                label: 'Générations fichiers',
                url: ''
            },
            {
                label: 'CachePrice check',
                url: ''
            }
        ];
        this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
    }

    getCachePrice() {
        this.utilsService.loadingShow()
        this.fileGenerationService.getCachePrice(this.brand,this.requesterCode,this.productCode).subscribe(
            cachePrice => {
                this.cachePriceData = cachePrice;
                this.utilsService.loadingHide()
            },
            error => {
                this.cachePriceData = null;
                this.utilsService.loadingHide()
                this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
            }
        )
    }
    showHideSearchEngine(){
        this.searchEngineHidden = !this.searchEngineHidden;
    }

    customSearchClient(term: string, item) {
        term = term.toLocaleLowerCase();
        return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
    }
}
