<div class="modal-header">
  <h4 class="modal-title">{{message == 'edit' ? 'Génération ' + fileGeneration.fileTypeName + ' ' + fileGeneration.brandName + ' pour ' + fileGeneration.requesterName : 'Nouvelle génération'}}</h4>

  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <form  method="post" name="parameter" #AddEditForm="ngForm" (ngSubmit)="onSubmit(AddEditForm);">
    <div >
      <div class="row">

        <!-- Type de fichier -->
        <div class="col-lg-6">
          <div class="mb-3">
            <label>
              Type de fichier <span class="red" *ngIf="message == 'add'">*</span>
              <ng-select [loading]="loadingFileType" [notFoundText]="'Aucun type de fichier'" [markFirst]="false"  [clearable]="false" [searchable]="(fileTypeList$ | async)?.length  > 10" [items]="fileTypeList$ | async" #fileType="ngModel" id="fileType" labelForId="fileType" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Type de fichier" required minlength="1" [(ngModel)]="fileGeneration.fileType">
              </ng-select>
            </label>
            <div [hidden]="fileType.valid  || fileType.pristine" class="invalid-feedback">
              Le type de fichier est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>

        <!-- Marque -->
        <div class="col-lg-6">
          <div class="mb-3">
            <label>
              Marque <span class="red" *ngIf="message == 'add'">*</span>
              <ng-select [loading]="loadingBrand" [notFoundText]="'Aucune marque'" [markFirst]="false" [clearable]="false" [searchable]="(brandList$ | async)?.length  > 10" [items]="brandList$ | async" #brand="ngModel" id="brand" labelForId="brand" [ngModelOptions]="{standalone: true}" bindValue="code" bindLabel="name" placeholder="Marque" required  [(ngModel)]="fileGeneration.brand">
              </ng-select>
            </label>
            <div [hidden]="brand.valid  || brand.pristine" class="invalid-feedback">
              La marque est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>

        <!-- Requester code -->
        <div class="col-lg-6">
          <div class="mb-3">
            <label>
              Requester code
              <div class="form-group">
                <ng-select [notFoundText]="'Aucun client'" [markFirst]="false" [clearable]="false" [searchable]="(xftUserList$ | async)?.length  > 10" [searchFn]="customSearchClient" [items]="xftUserList$ | async" id="client" labelForId="client" #client="ngModel" [ngModelOptions]="{standalone: true}" bindValue="requesterCode" bindLabel="name" placeholder="Requester code" required [(ngModel)]="fileGeneration.requesterCode">
                  <ng-template ng-option-tmp  ng-label-tmp let-item="item" let-index="index" let-search="searchTerm">
                    {{item.requesterCode}} - {{item.name}} ({{item.backofficeCode}})
                  </ng-template>
                </ng-select>
                <div [hidden]="client.valid  || client.pristine" class="invalid-feedback">
                  La client est obligatoire (min 3 caractères)
                </div>
              </div>
            </label>
          </div>
        </div>
        <!-- isActive -->
        <div class="col-lg-1">
          <div class="custom-switch mb-3">
            <br>
            <input  type="checkbox" id="status-switch" name="status" ngModel [(ngModel)]="fileGeneration.isActive" class="custom-control-input form-control">
            <label for="status-switch" class="custom-control-label mr-1">
              <span>Actif</span>
            </label>
          </div>
        </div>
        <div class="col-lg-12">
          <div class="mb-3">
            <span class="label" >heures de générations</span>
            <p>
              <span class="badge bg-light-secondary mb-1 mr-3" *ngFor="let hour of generationHours" style="width: 80px;">
                <input  type="checkbox"
                        class="filled-in"
                        id="hour_{{hour|generationHour}}"
                        [value]="hour|generationHour"
                        [checked]="hasHour(hour|generationHour)"
                        (change)="selectHour($event, hour)"/>
                {{hour|generationHour}}
              </span>
            </p>
          </div>
        </div>
        <div class="col-lg-12 submit-btn">
          <button class="pull-right btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit" [disabled]="!AddEditForm.form.valid || fileGeneration.fileType == null || fileGeneration.brand == null || fileGeneration.requesterCode == null" >
            <i class="ft-save"></i> Enregistrer
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
