import {Component, OnDestroy, OnInit} from '@angular/core';
import {NotificationService} from '../../shared/services/notification.service';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import { Observable, Subscription} from 'rxjs';
import {FileGenerationService} from '../../shared/services/midOffice/file-generation.service';
import {UtilsService} from '../../shared/services/utils.service';
import {AuditLogComponent} from '../../shared/audit-log/audit-log.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {FileGenerationFormComponent} from '../form/file-generation-form/file-generation-form.component';
import swal from 'sweetalert2';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Title} from '@angular/platform-browser';
import {CronWorkerService} from '../../shared/services/cron-worker/cron-worker.service';
import {shareReplay, tap} from 'rxjs/operators';
import {XftUserService} from '../../shared/services/midOffice/xft-user.service';
import {GenerationHourPipe} from '../../shared/pipes/generation-hour.pipe';
import {TokenService} from '../../shared/services/token.service';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-file-generation-list',
  templateUrl: './file-generation-list.component.html',
  styleUrls: ['./file-generation-list.component.scss']
})
export class FileGenerationListComponent implements OnInit, OnDestroy {
    alertNbGeneration = 10;
  fileGenerationList: any =null
  fileGenerationListOrigin: any =null
  fileTypeList$: Observable<any[]>;
  brandList$: Observable<any[]>;
  xftUserList$: Observable<any[]>;

  page: number = 1;
  totalCount:number
  fileType:string|null = 'cache-price';
  brand:string|null = 'maxula';
  requesterCode:number|null  = null;
  status:boolean|null = true;
  statusList = [
    { value: true, name: 'Actif' },
    { value: false, name: 'Inactif' },
  ];
    planner:boolean  = false;
    generationHours: Array<number> = [];
    loadingBrand: boolean = true;
    loadingFileType: boolean = true;
    loadingXftUser: boolean = true;
    searchEngineHidden: boolean = (window.innerWidth < 1200 )

    getFileGenerationsSubscription: Subscription
    deleteFileGenerationSubscription: Subscription
    getFileGenerationAuditSubscription: Subscription
    scheduleFileGenerationSubscription: Subscription
    addEditFileGenerationSubscription: Subscription

    updatedGeneration = {};
    nbGenerationToUpdate: number = 0;
  constructor(private fileGenerationService: FileGenerationService,private parameterService: ParameterService,private xftUserService: XftUserService,private cronWorkerService: CronWorkerService,private title: Title,private notifyService : NotificationService, private utilsService: UtilsService, public tokenService: TokenService,private modalService: NgbModal,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService) { }
    ngOnInit(): void {
      this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Générations fichiers XFT - Maxula Travel");
        this.utilsService.loadingShow()
        this.brandList$ = this.parameterService.getBrands().pipe(tap(() => this.loadingBrand = false),shareReplay(1));
        this.fileTypeList$ = this.parameterService.getFileType().pipe(tap(() => this.loadingFileType = false),shareReplay(1));
        this.xftUserList$ = this.xftUserService.getAllXftUsers().pipe(tap(() => this.loadingXftUser = false),shareReplay(1));
        this.getFileGenerations()
        const breadcrumbs  =  [
          {
              label: 'Accueil',
              url: '/'
          },
          {
              label: 'Générations fichiers',
              url: ''
          },
          {
              label: 'Liste générations fichiers',
              url: ''
          }
        ];
        this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
        this.generationHours = Array(24).fill(1).map((x,i)=>i);

    }
    ngOnDestroy() {
        if (this.getFileGenerationsSubscription) {
            this.getFileGenerationsSubscription.unsubscribe();
        }
        if (this.deleteFileGenerationSubscription) {
            this.deleteFileGenerationSubscription.unsubscribe();
        }
        if (this.getFileGenerationAuditSubscription) {
            this.getFileGenerationAuditSubscription.unsubscribe();
        }
        if (this.scheduleFileGenerationSubscription) {
            this.scheduleFileGenerationSubscription.unsubscribe();
        }
        if (this.addEditFileGenerationSubscription) {
            this.addEditFileGenerationSubscription.unsubscribe();
        }
    }
    getFileGenerations()
    {
        this.utilsService.loadingShow()
        this.getFileGenerationsSubscription = this.fileGenerationService.getFileGenerations(this.fileType,this.brand,this.requesterCode,this.status).subscribe(
          fileGenerationList => {
            this.fileGenerationList = [];
            for (let item in fileGenerationList) {
              this.fileGenerationList.push(fileGenerationList[item]);
            }
            this.fileGenerationListOrigin = structuredClone(this.fileGenerationList);
            this.totalCount = this.fileGenerationList.length;
            this.utilsService.loadingHide()
          },
          error => {
              this.fileGenerationList = [];
                this.utilsService.loadingHide()
                this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
          }
        )
    }
    deleteFileGeneration(id) {
      swal.fire({
          title: '',
          text: "Êtes-vous sûr de vouloir supprimer cette génération ?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#124a9c',
          cancelButtonColor: '#F55252',
          confirmButtonText: 'Supprimer',
          cancelButtonText: 'Annuler',
          customClass: {
              confirmButton: 'btn btn-warning',
              cancelButton: 'btn btn-danger ml-1'
          },
          buttonsStyling: false,
      }).then((result) => {
          if (result.isConfirmed) {
              this.utilsService.loadingShow();
              this.deleteFileGenerationSubscription = this.fileGenerationService.deleteFileGeneration(id).subscribe(
                  () => {
                      this.notifyService.showSuccess('Suppression effectuée avec succès',"")
                      this.getFileGenerations()
                  },
                  error => {
                      this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
                  }
              )
          }
      });
    }
    auditLog(id:string) {
        this.utilsService.loadingShow();
        this.getFileGenerationAuditSubscription = this.fileGenerationService.getFileGenerationAudit(id).subscribe(
            audit => {
                const modalRef = this.modalService.open(AuditLogComponent,{ size: 'lg'});
                modalRef.componentInstance.title = 'Log modification';
                modalRef.componentInstance.auditLog = audit.auditLog;
                this.utilsService.loadingHide();
            },
            error => {
                this.utilsService.loadingHide();
                this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
            }
        )
    }

    addEdit(fileGeneration = null) {
        const modalRef = this.modalService.open(FileGenerationFormComponent,{ size: 'xl',centered:false});
        modalRef.componentInstance.fileGeneration = fileGeneration !== null ? fileGeneration : {isActive:true};
        modalRef.componentInstance.message = fileGeneration !== null ? 'edit' : 'add';
        modalRef.componentInstance.xftUserList$ = this.xftUserList$;
        modalRef.componentInstance.fileType = this.fileType;
        modalRef.componentInstance.brand = this.brand;
        modalRef.result.then(
            () => {
                this.getFileGenerations();
            });
    }


    schedule(id: number) {
        this.scheduleFileGenerationSubscription = this.cronWorkerService.scheduleFileGeneration(id).subscribe(
            () => {
                this.notifyService.showSuccess('Génération ajoutée à la fil d\'attente',"")
            },
            error => {
                this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")

            }
        );

    }

    countGenerationsGyHour(valueToCount: string): number {
        if(this.fileGenerationList) {
            return this.fileGenerationList.reduce((count, item) => {
                return count + item.schedule.filter(time => time === valueToCount).length;
            }, 0);
        }
        return  0;
    }
    countGenerations(): number {
        if(this.fileGenerationList) {
            return this.fileGenerationList.reduce((sum, item) => {
                return sum + item.schedule.length;
            }, 0);
        }
        return  0;
    }

    formatNumber(num: number): string {
        return num < 10 ? `0${num}` : num.toString();
    }

    customSearchClient(term: string, item) {
        term = term.toLocaleLowerCase();
        return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
    }
    showHideSearchEngine(){
        this.searchEngineHidden = !this.searchEngineHidden;
    }

    updateHour(fileGeneration: any, hourInt: number) {

        let hour = new GenerationHourPipe().transform(hourInt);
        if(fileGeneration.schedule === undefined) {
            fileGeneration.schedule = [];
        }
        let existHour = fileGeneration.schedule.indexOf(hour) > -1;
        if(!existHour){
            fileGeneration.schedule.push(hour)
        }else {
            const index = fileGeneration.schedule.indexOf(hour);
            fileGeneration.schedule.splice(index,1)
        }
        this.updatedGeneration[fileGeneration.id] = fileGeneration
    }
    areArraysDifferent(): boolean {
        // Logique de comparaison des tableaux ici
        return JSON.stringify(this.fileGenerationListOrigin) !== JSON.stringify(this.fileGenerationList);
    }

    async updatePlanning() {
        this.utilsService.loadingShow()
        let index = 0;
        this.nbGenerationToUpdate = Object.keys(this.updatedGeneration).length;
        for (let item in this.updatedGeneration) {
            setTimeout(() => {
                this.addEditFileGenerationSubscription = this.fileGenerationService.addEditFileGeneration(this.updatedGeneration[item]?.id ?? null,this.updatedGeneration[item]).subscribe(
                    () => {
                        this.notifyService.showSuccess('Mise à jour pour '+this.updatedGeneration[item].requesterName+' effectuée avec succès',"")
                        delete this.updatedGeneration[item];
                        this.nbGenerationToUpdate--;
                        if(this.nbGenerationToUpdate == 0) {
                            //this.utilsService.loadingHide()
                            this.getFileGenerations()
                        }
                    },
                    error => {
                        this.notifyService.showError(error.error.message ?? (error.message ?? '') + ' ('+this.updatedGeneration[item].requesterName+')',"")
                        this.nbGenerationToUpdate--;
                        if(this.nbGenerationToUpdate == 0) {
                            this.utilsService.loadingHide()
                        }
                    }
                )
            }, index*500)
            index++;
        }
    }

    clearGeneration(fileGeneration: any = null) {
        if(fileGeneration){
            fileGeneration.schedule = []
            this.updatedGeneration[fileGeneration.id] = fileGeneration
        } else {
            for (let item in this.fileGenerationList) {
                this.fileGenerationList[item].schedule = []
                this.updatedGeneration[this.fileGenerationList[item].id] = this.fileGenerationList[item]
            }
        }
    }
}
