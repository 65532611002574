import { RouteInfo } from './vertical-menu.metadata';
import {TokenService} from '../services/token.service';
//Sidebar menu Routes and data
let tokenService = new TokenService()

export const ROUTES: RouteInfo[] = [
    {
      authorized: tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '', title: 'Logs', icon: 'ft-layers', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
      submenu: [
        { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/xft-transaction/log', title: 'Transactions XFT', icon: 'ft-repeat submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        //{ authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/flight-transaction', title: 'Flight', icon: 'fa fa-plane submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { authorized:tokenService.checkRole([]), path: '/api-log', title: 'API', icon: 'ft-cloud submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      ]
    },
    {
      authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '', title: 'Rapports', icon: 'ft-clipboard', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
      submenu: [
        { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/xft-transaction/transaction-report', title: 'Transactions XFT', icon: 'ft-trending-up submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
        { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/xft-transaction/error-report', title: 'Rapport des Erreurs XFT', icon: 'ft-alert-triangle submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

      ]
    },
  {
    authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '', title: 'Générations fichiers', icon: 'ft-file-plus', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/file-generation/monitoring', title: 'Suivi des générations', icon: 'ft-check-circle submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          /*{ authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/file-generation/monitoring/all/cache-price', title: 'Cache Price', icon: 'ft-dollar-sign submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
          { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/file-generation/monitoring/all/ceto', title: 'Catalogue CETO', icon: 'ft-file-text submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
*/
        ]
      },
      { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/file-generation/configuration', title: 'Liste des générations', icon: 'ft-list submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/file-generation/time', title: 'Temps des générations', icon: 'ft-clock submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },
      { authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/file-generation/cache-price', title: 'CachePrice check', icon: 'ft-check submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [] },

    ]
  },
  {
    authorized:tokenService.checkRole(['ROLE_ADMIN','ROLE_MIDOFFICE']), path: '/xft-user', title: 'Gestion Clients', icon: 'ft-user', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  /*{
    authorized:tokenService.checkRole(['ROLE_ADMIN']), path: '', title: 'Connecteurs', icon: 'fa fa-plug', class: 'has-sub hidden', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        authorized:tokenService.checkRole(['ROLE_ADMIN']), path: '', title: 'Hôtels', icon: 'fa fa-hotel submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          {
            authorized: tokenService.checkRole(['ROLE_ADMIN']), path: '/connecteur/hotel/search', title: 'Recherche', icon: 'ft-search submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false,submenu: []
          }
        ]
      },

    ]
  },
  {
    authorized:tokenService.checkRole(['ROLE_ADMIN']), path: '', title: 'Caches', icon: 'fa fa-database', class: 'has-sub', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false,
    submenu: [
      {
        authorized:tokenService.checkRole(['ROLE_ADMIN']), path: '', title: 'Hôtels', icon: 'fa fa-hotel submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false,
        submenu: [
          {
            authorized: tokenService.checkRole(['ROLE_ADMIN']), path: '/cache/hotel/list', title: 'List', icon: 'ft-list submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          },
          {
            authorized: tokenService.checkRole(['ROLE_ADMIN']), path: '/cache/hotel/price', title: 'Pricing', icon: 'ft-briefcase submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
          }
        ]
      },

    ]
  },*/
  {
    authorized:tokenService.checkRole(['ROLE_ADMIN']), path: '/user', title: 'Utilisateurs', icon: 'ft-user', class: 'menu-sep', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    authorized:tokenService.checkRole([]), path: '/cron', title: 'Crons', icon: 'ft-clock', class: 'menu-sep', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
  {
    authorized:tokenService.checkRole([]), path: '/api-user', title: 'API Users', icon: 'ft-users', class: '', badge: '',badgeClass: '',  isExternalLink: false, submenu: []
  },
  /*{
    authorized:tokenService.checkRole([]), path: '/locality', title: 'Localités', icon: 'fa  fa-map-marker', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },*/
  {
    authorized:tokenService.checkRole([]), path: '/parameter', title: 'Paramètres', icon: 'ft-settings', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: []
  },
];
