import {Component, OnDestroy, OnInit} from '@angular/core';
import {NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {Observable, Subscription} from 'rxjs';
import {XftUserService} from '../../shared/services/midOffice/xft-user.service';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {NotificationService} from '../../shared/services/notification.service';
import {UtilsService} from '../../shared/services/utils.service';
import {XftTransactionService} from '../../shared/services/log/xft-transaction.service';
import {GlobalConstants} from '../../shared/global-constants';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import {Title} from '@angular/platform-browser';
import {shareReplay, tap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-xft-transaction-log-error-report',
  templateUrl: './xft-transaction-log-error-report.component.html',
  styleUrls: ['./xft-transaction-log-error-report.component.scss']
})
export class XftTransactionLogErrorReportComponent implements OnInit, OnDestroy {
  now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
  xftTransactionErrorReportList: any[]|null;
  xftUserList$: Observable<any[]>;
  networkList$: Observable<any[]>;
  subNetworkList$: Observable<any[]>;

  page: number = 1;
  pageSize: number;
  totalCount: number = 0
  pageCount: number = 1

  errorDate:NgbDateStruct;
  productCode:string|null = null;
  requesterCode:string|null = null;
  requesterNetworkCode:string|null = null;
  requesterSubNetworkCode:string|null = null;

  maxDate: NgbDateStruct;
  loadingXftUser: boolean = true;
  searchEngineHidden: boolean = (window.innerWidth < 1200 )
  xftTransactionErrorReportSearchSubscription: Subscription
  constructor(private xftTransactionService: XftTransactionService,private xftUserService: XftUserService,private parameterService: ParameterService,private title: Title,private notifyService : NotificationService,private utilsService : UtilsService,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService){}
  ngOnInit(): void {
    this.now = new Date(new Date().toLocaleString("en-US", {timeZone: "Europe/Paris"}));
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Rapport des erreurs XFT - Maxula Travel");
    this.pageSize = GlobalConstants.pageSize;
    this.maxDate = {year: this.now.getFullYear(), month: this.now.getMonth() + 1, day: this.now.getDate()};
    this.errorDate = this.maxDate;
    // REQUESTER
    this.xftUserList$ = this.xftUserService.getAllXftUsers().pipe(tap(() => this.loadingXftUser = false),shareReplay(1));
    this.networkList$ = this.xftUserService.getXftUsersNetworks()
    this.subNetworkList$ = this.xftUserService.getXftUsersSubNetworks();
    this.searchErrorReport()
    const breadcrumbs  =  [
      {
        label: 'Accueil',
        url: '/'
      },
      {
        label: 'Transactions XFT',
        url: ''
      },
      {
        label: 'Rapport des Erreurs',
        url: ''
      }
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);
  }
  ngOnDestroy() {
    if (this.xftTransactionErrorReportSearchSubscription) {
      this.xftTransactionErrorReportSearchSubscription.unsubscribe();
    }
  }
  onSubmit(){
    this.page = 1;
    this.searchErrorReport()
  }
  searchErrorReport(){
    this.utilsService.loadingShow();
    this.xftTransactionErrorReportSearchSubscription = this.xftTransactionService.xftTransactionErrorReportSearch(
      this.errorDate,
      this.productCode,
      this.requesterCode,
      this.requesterNetworkCode,
      this.requesterSubNetworkCode,
      this.page
    ).subscribe(
      response => {
        this.totalCount = response.totalCount;
        this.pageCount = response.nbPage;
        this.xftTransactionErrorReportList = [];
        for (let item in response.xftTransactionLogError) {
          this.xftTransactionErrorReportList.push(response.xftTransactionLogError[item]);
        }
        this.utilsService.loadingHide();
      },
      error => {
        this.utilsService.loadingHide();
        this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
      }
    )
  }

  paginate(page: number|null = null) {
    this.page = page ?? this.page;
    if(this.page>this.pageCount) {
      this.page = this.pageCount-1
    }
    this.searchErrorReport()

  }

  downloadFile() {
    let errorList = [];
    for (let item in this.xftTransactionErrorReportList) {
      if (errorList[this.xftTransactionErrorReportList[item].productCode] === undefined ) {
        errorList[this.xftTransactionErrorReportList[item].productCode] = [];
      }
      errorList[this.xftTransactionErrorReportList[item].productCode].push({
        'Référence': this.xftTransactionErrorReportList[item].productCode,
        'Nom produit': this.xftTransactionErrorReportList[item].productName,
        'Pays': this.xftTransactionErrorReportList[item].productCountryName,
        'Ville': this.xftTransactionErrorReportList[item].productCityName,
        'Motif d\'échec': this.xftTransactionErrorReportList[item].unavailabilityMessage,
        'Date de départ': this.xftTransactionErrorReportList[item].departureDate,
        'Ville de départ': this.xftTransactionErrorReportList[item].departureCity,
        'Adultes': this.xftTransactionErrorReportList[item].nbAdult,
        'Enfants': this.xftTransactionErrorReportList[item].nbChild,
        'Bébés': this.xftTransactionErrorReportList[item].nbInfant,
        'RequesterCode': this.xftTransactionErrorReportList[item].requesterCode,
        'RequesterName': this.xftTransactionErrorReportList[item].requesterName,
        'Nombre d\'alertes': this.xftTransactionErrorReportList[item].errorCount,
      });
    }
    let title = 'Rapport des erreurs du ';
    title += (1e15 + this.errorDate.day + '').slice(-2) + '/';
    title += (1e15 + this.errorDate.month + '').slice(-2) + '/';
    title += this.errorDate.year;


    let fileName = 'Rapport des erreurs du ';
    fileName += (1e15 + this.errorDate.day + '').slice(-2) + '-';
    fileName += (1e15 + this.errorDate.month + '').slice(-2) + '-';
    fileName += this.errorDate.year;

    this.utilsService.excelExport(errorList,title,fileName,true)
  }
  customSearchClient(term: string, item) {
    term = term.toLocaleLowerCase();
    return item.requesterCode.toString().toLocaleLowerCase().indexOf(term) > -1 || item.name.toLocaleLowerCase().indexOf(term) > -1 || item.backofficeCode.toLocaleLowerCase().indexOf(term) > -1;
  }
  showHideSearchEngine(){
    this.searchEngineHidden = !this.searchEngineHidden;
  }
}
