import {Component, OnDestroy, OnInit} from '@angular/core';
import {ParameterService} from '../../shared/services/midOffice/parameter.service';
import {NotificationService} from '../../shared/services/notification.service';
import {UtilsService} from '../../shared/services/utils.service';
import {GlobalConstants} from '../../shared/global-constants';
import {AuditLogComponent} from '../../shared/audit-log/audit-log.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ParameterFormComponent} from '../form/parameter-form/parameter-form.component';
import swal from 'sweetalert2';
import {NgDynamicBreadcrumbService} from 'ng-dynamic-breadcrumb';
import { Title } from "@angular/platform-browser";
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';
@Component({
  selector: 'app-parameter-list',
  templateUrl: './parameter-list.component.html',
  styleUrls: ['./parameter-list.component.scss', '../../../assets/sass/libs/select.scss']
})
export class ParameterListComponent  implements OnInit, OnDestroy {
  parameterList: any = null;
  page: number = 1;
  pageSize: number;
  totalCount: number = 0
  pageCount: number = 1

  name: string = '';
  status: boolean|null = true;
  master: boolean|null = null;

  statusList = [
    { value: true, name: 'Actif' },
    { value: false, name: 'Inactif' },
  ];
  masterList = [
    { value: true, name: 'Oui' },
    { value: false, name: 'Non' },
  ];
  searchEngineHidden: boolean = (window.innerWidth < 1200 )
  getParametersSubscription: Subscription
  deleteParameterSubscription: Subscription
  getParameterAuditSubscription: Subscription
  constructor(private parameterService: ParameterService,private notifyService : NotificationService,private title: Title, private utilsService: UtilsService,private modalService: NgbModal,private ngDynamicBreadcrumbService: NgDynamicBreadcrumbService){

  }
  ngOnInit(): void {
    this.title.setTitle((environment.server != 'PRODUCTION' ? environment.server + ' - ' : '') + "Paramètres - Maxula Travel");
    this.pageSize = GlobalConstants.pageSize;
    this.searchParameter()

    const breadcrumbs  =  [
      {
        label: 'Accueil',
        url: '/'
      },
      {
        label: 'Paramètres',
        url: ''
      },
    ];
    this.ngDynamicBreadcrumbService.updateBreadcrumb(breadcrumbs);

  }
  ngOnDestroy() {
    if (this.getParametersSubscription) {
      this.getParametersSubscription.unsubscribe();
    }
    if (this.deleteParameterSubscription) {
      this.deleteParameterSubscription.unsubscribe();
    }
    if (this.getParameterAuditSubscription) {
      this.getParameterAuditSubscription.unsubscribe();
    }
  }
  searchParameter() {
    this.utilsService.loadingShow();
    this.getParametersSubscription = this.parameterService.getParameters(this.page,this.name,this.status, this.master).subscribe(
      resultList => {
        this.parameterList = [];
        this.totalCount = resultList.totalCount;
        this.pageCount = resultList.nbPage;
        for (let i in resultList.parameters) {
          this.parameterList.push(resultList.parameters[i]);
        }
        this.utilsService.loadingHide();
      },
      error => {
        this.utilsService.loadingHide();
        this.notifyService.showError(error?.error?.message ?? (error?.message ?? ''),"")
        this.parameterList = [];
      }
    )


  }

  deleteParameter(code: string) {
    swal.fire({
      title: '',
      text: "Êtes-vous sûr de vouloir supprimer ce paramètre ?",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#124a9c',
      cancelButtonColor: '#F55252',
      confirmButtonText: 'Supprimer',
      cancelButtonText: 'Annuler',
      customClass: {
        confirmButton: 'btn btn-warning',
        cancelButton: 'btn btn-danger ml-1'
      },
      buttonsStyling: false,
    }).then((result) => {
      if (result.isConfirmed) {
        this.utilsService.loadingShow();
        this.deleteParameterSubscription = this.parameterService.deleteParameter(code).subscribe(
            () => {
              this.notifyService.showSuccess('Suppression effectuée avec succès',"")
              this.paginate();
            },
            error => {
              this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
            }
        )
      }
    });
  }


  paginate(page: number|null = null) {
    this.page = page ?? this.page;
    if(this.page>this.pageCount) {
      this.page = this.pageCount-1
    }
    this.searchParameter()

  }
  auditLog(code:string) {
    this.utilsService.loadingShow();
    this.getParameterAuditSubscription = this.parameterService.getParameterAudit(code).subscribe(
        audit => {
          const modalRef = this.modalService.open(AuditLogComponent,{ size: 'lg'});
          modalRef.componentInstance.title = 'Log modification';
          modalRef.componentInstance.auditLog = audit.auditLog;
          this.utilsService.loadingHide();
        },
        error => {
          this.utilsService.loadingHide();
          this.notifyService.showError(error.error.message ?? (error.message ?? ''),"")
        }
    )
  }

    addEdit(parameter = null) {
      const modalRef = this.modalService.open(ParameterFormComponent,{ size: 'lg'});
      modalRef.componentInstance.parameterCode = parameter !== null ? parameter.code : '';
      modalRef.componentInstance.parameter = parameter !== null ? parameter : {isActive:true};
      modalRef.componentInstance.message = parameter !== null ? 'edit' : 'add';
      modalRef.result.then(
          () => {
            this.paginate(this.page);
          });
    }
  showHideSearchEngine(){
    this.searchEngineHidden = !this.searchEngineHidden;
  }
}
