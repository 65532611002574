
<div class="content-wrapper">
  <div style="text-align: center;font-size: 24px;">
    <span>Serveur : <strong>{{server}}</strong></span>
  </div>
  <div class="row">
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="card bg-lighten-3">
        <a href="/xft-transaction/log">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body text-left">
                  <h3 class="font-large-1 info mb-0">Log XFT</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-repeat font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12"  *ngIf="tokenService.checkRole([])">
      <div class="card bg-lighten-3">
        <a href="/api-log">
          <div class="card-content">
            <div class="card-body">
              <div class="media">
                <div class="media-body info text-left">
                  <h3 class="font-large-1 info mb-0">Log API</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-cloud font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="card bg-lighten-3">
        <a href="/file-generation/monitoring">
          <div class="card-content">
            <div class="card-body">
              <div class="media" style="padding-bottom: 0">
                <div class="media-body info text-left">
                  <h3 class="font-large-1 info mb-0">Générations Cache Price</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-check-circle  font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col-xl-6 col-lg-6 col-md-6 col-12">
      <div class="card bg-lighten-3">
        <a href="/file-generation/monitoring/all/ceto">
          <div class="card-content">
            <div class="card-body">
              <div class="media" style="padding-bottom: 0">
                <div class="media-body info text-left">
                  <h3 class="font-large-1 info mb-0">Générations CETO</h3>
                  <span>&nbsp;</span>
                </div>
                <div class="media-right info text-right">
                  <i class="ft-check-circle  font-large-1"></i>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
</div>

