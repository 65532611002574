<div class="row">
    <div class="col-12">
        <div class="card shadow-none">
            <div class="header">
                <i class="ft-chevron-{{searchEngineHidden ? 'down' : 'up'}} accordion-button-up" (click)="showHideSearchEngine()"></i>
                <h6 class="text-white ml-2 title-header" >Crons</h6>
                <i class="ft-plus-circle btn-add with-reload" (click)="addEdit()" title="Nouveau cron"></i>
                <i class="ft-refresh-cw btn-search-engine-reload" (click)="searchEngineForm.ngSubmit.emit()" title="Actualiser"></i>
            </div>
            <div class="card-content">
                <div class="card-body card-search-engine" [hidden]="searchEngineHidden">
                    <form (ngSubmit)="onSubmit();" #searchEngineForm="ngForm">
                        <div class="row" style="display: flex!important;">
                            <!-- Code -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="name">Code</label>
                                    <input class="form-control" id="code" name="code" ngModel type="text" placeholder="Code" value="" [(ngModel)]="code" autocomplete="off">
                                </div>
                            </div>
                            <!-- Name -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label for="name">Nom</label>
                                    <input class="form-control" id="name" name="name" ngModel type="text" placeholder="Nom" value="" [(ngModel)]="name" autocomplete="off">
                                </div>
                            </div>
                            <!-- Etat -->
                            <div class="col-md-3">
                                <div class="form-floating mb-3">
                                    <label>
                                        Statut
                                        <ng-select [notFoundText]="'Aucun état'" [markFirst]="false" [searchable]="false" [items]="statusList" [ngModelOptions]="{standalone: true}" id="status" labelForId="status" bindValue="value" bindLabel="name" placeholder="Tous" [(ngModel)]="status">
                                        </ng-select>
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <button class="btn btn-primary btn-float-right" type="submit" id="search">
                                    <i class="ft-search mr-1"></i>Rechercher
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="card-body" id="scrollTo">
                    <h4 class="card-title">{{totalCount}} cron{{totalCount>1 ? 's' : ''}}</h4>
                    <div class="table-responsive">
                        <div class="table-responsive">
                            <table class="table table-striped">
                                <thead class="thead-light">
                                <tr>
                                    <th  style="width: 15% !important;min-width: 200px">Code</th>
                                    <th  style="width: 20% !important;min-width: 330px" >Nom</th>
                                    <th  style="width: 15% !important;min-width: 230px;">Configuration</th>
                                    <th  style="width: auto !important; min-width: 400px">
                                        Dernière exécution
                                        <i class="ft-refresh-ccw" (click)="updateCronLastExecution()" style="margin-left: 15px; cursor:pointer;"></i>
                                    </th>
                                    <th  style="width: 5% !important;">Statut</th>
                                    <th style="width: 10% !important;min-width: 180px"></th>
                                </tr>
                            </thead>
                            <tbody *ngIf="cronList?.length != 0" >
                            <tr *ngFor="let cron of cronList " >
                                <td>{{cron.code}}</td>
                                <td>
                                    {{cron.name}}
                                    <!--<i class="ft-info" style="font-size: 14px !important; margin-left: 1px !important;" *ngIf="cron.dataInfo.length" container="body" tooltipClass="myTooltip" placement="top" ngbTooltip="{{cron.dataInfo}}"></i>-->
                                    <i class="ft-info" style="font-size: 14px !important; margin-left: 1px !important;" *ngIf="cron.dataInfo.length" container="body" tooltipClass="myTooltip" placement="top"  [autoClose]="true" [ngbTooltip]="list"></i>
                                    <ng-template #list>
                                        <div class="tooltip-template">
                                            <pre>{{cron.dataInfo}}</pre>
                                        </div>
                                    </ng-template>

                                </td>
                                <td style="text-transform: capitalize">{{cronToText(cron.configuration)}}</td>
                                <td>
                                    <span *ngIf="cron.lastExecutionDate == null"><img src="/assets/img/loading.gif" width="20" alt="Chargement"></span>
                                    <div *ngIf="cron.lastExecutionDate !== null && cron.lastExecutionDate.length" >
                                        <div style="white-space: nowrap">
                                            <span *ngIf='cron.lastExecutionEndDate && cron.lastExecutionEndDate.length'>
                                                <i *ngIf="cron.isError" class="ft-x-circle danger" style="margin-left: 0 !important;" title="En erreur"   (click)="getSteps(cron.lastExecutionId)"></i>
                                                <i *ngIf="!cron.isError && !cron.isWarning && cron.isError !== null" class="ft-check-circle {{ cron.todayExecution ? 'success' : 'warning' }}" style="margin-left: 0 !important;" title="Succès"   (click)="getSteps(cron.lastExecutionId)"></i>
                                                <i *ngIf="cron.isWarning" class="ft-alert-circle warning" style="margin-left: 0 !important;" title="En alerte"   (click)="getSteps(cron.lastExecutionId)"></i>
                                            </span>
                                            <span *ngIf='!cron.lastExecutionEndDate || !cron.lastExecutionEndDate.length'>
                                                <i  class="ft-settings {{ cron.todayExecution ? 'info' : 'warning' }}" style="margin-left: 0 !important;" title="En cours"   (click)="getSteps(cron.lastExecutionId)"></i>
                                            </span>
                                            {{cron.lastExecutionDate  | date:'dd/MM/YYYY' | dateText}}
                                            {{cron.lastExecutionDate  | date:'HH:mm:ss'}} -
                                            {{cron.lastExecutionMessage.substring(0, 45)}}<span *ngIf="cron.lastExecutionMessage.length > 45">...</span>
                                            <i class="ft-info" style="font-size: 14px !important; margin-left: 1px !important;" *ngIf="cron.lastExecutionMessage.length > 45" container="body" tooltipClass="myTooltip" placement="top" ngbTooltip="{{cron.lastExecutionMessage}}"></i>

                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <i *ngIf="cron.isActive" class="ft-play-circle" style="color: #40C057" title="Actif"></i>
                                    <i *ngIf="!cron.isActive" class="ft-stop-circle" style="color: red" title="Inactif"></i>
                                </td>
                                <td style="text-align: right">
                                    <a (click)="addEdit(cron)" title="Éditer">
                                        <i class="ft-edit-2" style="margin: 5px; color:green;"></i>
                                    </a>
                                    <a routerLink="/cron/{{cron.code}}/log" title="Log execution" target="_blank">
                                        <i class="ft-activity" style="margin: 5px; color:blue;"></i>
                                    </a>
                                    <a (click)="deleteCron(cron.code)" title="Supprimer">
                                        <i class="ft-x" style="margin: 5px; color:red;"></i>
                                    </a>
                                    <a (click)="auditLog(cron.code)" title="Log modification">
                                        <i class="ft-layers" style="margin: 5px; color:grey;"></i>
                                    </a>
                                    <a *ngIf="cron.url.length" (click)="executeCron(cronApiUrl + '/' + cron.url,cron)" title="Lancer le cron">
                                        <i class="ft-play" style="margin: 5px; color:#0080f9;"></i>
                                    </a>
                                    <a *ngIf="!cron.url.length" title="Url non configurée" style="cursor:not-allowed;">
                                        <i class="ft-play" style="margin: 5px; color:#c9c9c9;"></i>
                                    </a>

                                </td>
                            </tr>
                            </tbody>
                            <tbody *ngIf="cronList?.length == 0" >
                            <tr>
                                <td class="no-data-available" colspan="7" >Aucun cron</td>
                            </tr>
                            </tbody>
                        </table>
                        <div *ngIf="pageCount > 1">
                            <ngb-pagination [collectionSize]="totalCount"  [pageSize]="pageSize" (pageChange)="paginate($event)" [(page)]="page" [maxSize]="3" [rotate]="true" [boundaryLinks]="true"></ngb-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</div>
