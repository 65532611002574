<div class="modal-header">
  <h4 class="modal-title"><h4 class="modal-title">{{message == 'edit' ? cron.name : 'Nouveau cron'}}</h4></h4>

  <button type="button" style="border: 0; background: none" class="btn-close" aria-label="Close" (click)="activeModal.dismiss('Cross click')"><i class="ft-x-circle" ></i></button>
</div>
<div class="modal-body">
  <form  method="post" name="parameter" #AddEditForm="ngForm" (ngSubmit)="onSubmit(AddEditForm);">
    <div class="">
      <div class="row">

        <!-- code -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="code">Code <span class="red" >*</span></label>
            <input [ngClass]="{'is-invalid' : !code.valid && !code.pristine}"  [disabled]="isNumeric(cron.id)" class="form-control" id="code" maxlength="100" name="code"  placeholder="Code" [(ngModel)]="cron.code"   #code="ngModel" required minlength="3" type="text" autocomplete="off">
            <div [hidden]="code.valid  || code.pristine" class="invalid-feedback">
              Le code est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>

        <!-- Nom -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="name">Nom <span class="red" >*</span></label>
            <input [ngClass]="{'is-invalid' : !name.valid && !name.pristine}" class="form-control" id="name" maxlength="100" name="name" ngModel placeholder="Nom" #name="ngModel" [(ngModel)]="cron.name" required minlength="3" type="text" autocomplete="off">
            <div [hidden]="name.valid  || name.pristine" class="invalid-feedback">
              Le nom est obligatoire (min 3 caractères)
            </div>
          </div>
        </div>
        <!-- isActive -->
        <div class="col-lg-4">
          <div class="custom-switch mb-3">
            <br>
            <input  type="checkbox" id="status-switch" name="status" ngModel [(ngModel)]="cron.isActive" class="custom-control-input form-control">
            <label for="status-switch" class="custom-control-label mr-1">
              <span>Actif</span>
            </label>
          </div>
        </div>
        <!-- Descriptif -->
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="name">Description <span class="red" >*</span></label>
            <textarea rows="3" [ngClass]="{'is-invalid' : !description.valid && !description.pristine}" class="form-control" id="description" maxlength="256" name="description" ngModel placeholder="Description" required #description="ngModel" [(ngModel)]="cron.description"></textarea>
          </div>
        </div>

        <!-- Commande -->
        <div class="col-lg-6">
          <div class="mb-3">
            <label for="command">Commande <i class="fa fa-info-circle"  tooltipClass="myTooltip" placement="top" ngbTooltip="Voir les exemples ci-dessous"></i> </label>
            <input class="form-control" id="command" maxlength="256" name="command" ngModel placeholder="Commande" [(ngModel)]="cron.command"   type="text">
          </div>
        </div>
        <!-- Config -->
        <div class="col-lg-6">
          <div class="mb-3 fit-content-select">
            <label>
              Configuration
              <cron-jobs  [(ngModel)]="cron.configuration" [ngModelOptions]="{standalone: true}"></cron-jobs>
            </label>
          </div>
        </div>

        <!-- Url -->
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="url">Url</label>
            <div class="input-group prefix cron-api-url">
              <span class="input-group-addon cron-api-url-url">{{cronApiUrl}}/</span>
              <input class="form-control" id="url" maxlength="400" name="url" ngModel placeholder="Url" [(ngModel)]="cron.url"   type="text">
              <span *ngIf="cron?.url?.length" class="input-group-addon copy-url cron-api-url-copy" (click)="copyCronUrl(cronApiUrl + '/' + cron.url)" style="border-radius: 0 !important;"><i class="ft-copy"></i></span>
              <span *ngIf="cron?.url?.length" class="input-group-addon copy-url cron-api-url-copy" (click)="executeCron(cronApiUrl + '/' + cron.url,cron)" >
                <!--<a href="{{}}" target="_blank"></a>--><i class="ft-play" style="color: #555;" title="Lancer le cron"></i>
              </span>
            </div>

          </div>
        </div>
        <!-- Paramètres Url -->
        <div class="col-lg-12">
          <div class="mb-3">
            <label for="urlParameters">Paramètres </label>
            <textarea rows="3" class="form-control" id="urlParameters" maxlength="4000" name="urlParameters" ngModel placeholder="Paramètres" #description="ngModel" [(ngModel)]="cron.urlParameters"></textarea>
          </div>
        </div>
        <!-- Data 1 -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="data1Label">Donnée 1</label>
            <input class="form-control form-label" id="data1Label" maxlength="100" name="data1Label" ngModel placeholder="Label" [(ngModel)]="cron.data1Label"   type="text">
            <input class="form-control" id="data1" maxlength="100" name="data1" ngModel placeholder="Donnée 1" [(ngModel)]="cron.data1"   type="text">
          </div>
        </div>
        <!-- Data 2 -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="data2Label">Donnée 2</label>
            <input class="form-control form-label" id="data2Label" maxlength="100" name="data2Label" ngModel placeholder="Label" [(ngModel)]="cron.data2Label"   type="text">
            <input class="form-control" id="data2" maxlength="100" name="data2" ngModel placeholder="Donnée 2" [(ngModel)]="cron.data2"   type="text">
          </div>
        </div>
        <!-- Data 3 -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="data3Label">Donnée 3</label>
            <input class="form-control form-label" id="data3Label" maxlength="100" name="data3Label" ngModel placeholder="Label" [(ngModel)]="cron.data3Label"   type="text">
            <input class="form-control" id="data3" maxlength="100" name="data3" ngModel placeholder="Donnée 3" [(ngModel)]="cron.data3"   type="text">
          </div>
        </div>
        <!-- Execution Data 1 -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="executionData1Label">Execution data label 1</label>
            <input class="form-control" id="executionData1Label" maxlength="100" name="executionData1Label" ngModel placeholder="Donnée 1" [(ngModel)]="cron.executionData1Label"   type="text">
          </div>
        </div>
        <!-- Execution Data 2 -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="executionData2Label">Execution label 2</label>
            <input class="form-control" id="executionData2Label" maxlength="100" name="executionData2Label" ngModel placeholder="Donnée 2" [(ngModel)]="cron.executionData2Label"   type="text">
          </div>
        </div>
        <!-- Execution Data 3 -->
        <div class="col-lg-4">
          <div class="mb-3">
            <label for="executionData3Label">Execution label 3</label>
            <input class="form-control" id="executionData3Label" maxlength="100" name="executionData3Label" ngModel placeholder="Donnée 3" [(ngModel)]="cron.executionData3Label"   type="text">
          </div>
        </div>
        <div class="col-lg-12">
          Exemples :<br>
          &nbsp;&nbsp;&nbsp;&nbsp;Symfony command : <i>/usr/local/bin/php /var/www/bin/console app:test</i>

        </div>
        <div class="col-lg-12 float-end">

            <button class="pull-right btn btn-primary waves-effect btn-label waves-light modal-wait blocRightBottom" type="submit" [disabled]="!AddEditForm.valid">
              <i class="ft-save"></i> Enregistrer
            </button>
        </div>
      </div>
    </div>
  </form>
</div>
